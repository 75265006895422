<template>
  <header class="header">
    <nav>
      <img
        src="@/assets/img/logo_fqm_branco.svg"
        alt="Logo"
        @click="goToRoute('Solicitacoes')"
      />
      <ul>
        <div v-for="(link, index) in links" :key="index">
          <li
            v-if="
              checkPermission(link.permission) &&
              link.excludeTypePermission !== isRepresentante
              && customEnabled(link.customEnabled)"
          >
            <v-menu v-if="link.children.length" offset-y :open-on-hover="true">
              <template v-slot:activator="{ on, attrs }">
                <p v-bind="attrs" v-on="on">
                  {{ link.title }} <v-icon>mdi-chevron-down</v-icon>
                </p>
              </template>
              <v-list
                v-for="(child, index) in link.children"
                :key="index"
                class="pa-0"
              >
                <v-list-item
                  v-if="checkPermissionChild(child)
                  && customEnabled(child.customEnabled)"
                  class="sub-link"
                  @click="goToRoute(child.route)"
                  :disabled="child.route === routeName"
                >
                  <v-list-item-title>{{ child.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <p v-else @click="goToRoute(link.route)">{{ link.title }}</p>
          </li>
        </div>
      </ul>
    </nav>
    <v-btn
      class="ma-2"
      color="white"
      outlined
      solo
      @click="
        $emit('logout');
        loading = true;
      "
      :loading="loading"
    >
      Sair <v-icon right> mdi-exit-to-app </v-icon></v-btn
    >
  </header>
</template>

<script>
import { checkPermission } from "@/utils";
export default {
  name: "HeaderDesktop",
  props: {
    links: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    customEnabled(customEnabled) {
      let returnChecking = true;

      if (customEnabled === undefined || customEnabled === null || customEnabled === "") {
        return returnChecking;
      }
      customEnabled.forEach(element => {
        let checking = true;
        switch (element) {

          case 'notAccessibleToColaborador':
            checking = this.notAccessibleToType('Colaborador');
              if (!checking) {
                returnChecking = false;
              }
            break;

          case 'notAccessibleToAdmMesa':
            checking = this.notAccessibleToType('Administrador de mesa');
              if (!checking) {
                returnChecking = false;
              } 
            break;
        }
      });
      return returnChecking;
    },
    checkPermission,
    checkPermissionChild(child) {
      if (child.mesaId && child.excludeTypePermission.length > 0 && this.roleUser !== "Administrador master") {
        return (
          this.checkPermission(child.permission) &&
          child.excludeTypePermission.includes(this.roleUser) &&
          child.mesaId === this.mesaId
        );
      } else {
        return this.checkPermission(child.permission);
      }
    },
    goToRoute(route) {
      this.$router.push({ name: route });
    },
    notAccessibleToType(userType) {
      /* console.log(this.roleUser);
      console.log(this.userType);
      console.log(this.roleUser !== userType); */
      return this.roleUser !== userType;
    },
  },
  computed: {
    routeName() {
      return this.$route.name;
    },
    isRepresentante() {
      return this.$store.state.user.data.tipo;
    },
    roleUser() {
      return this.$store.state.user.data.tipo;
    },
    mesaId() {
      return this.$store.state.user.data.funcionario.mesaId;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #343a40;
  nav {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    img {
      max-width: 100px;
      cursor: pointer;
    }
    ul {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      list-style: none;
      gap: 1rem;
      p {
        margin: 0;
        color: #fff;
        i {
          color: #fff;
        }
        &:hover {
          color: rgba(255, 255, 255, 0.75);
          cursor: pointer;
          i {
            color: rgba(255, 255, 255, 0.5) !important;
          }
        }
      }
    }
  }
}
.sub-link:hover {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5) !important;
}
</style>
