var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',{staticClass:"header"},[_c('img',{attrs:{"src":require("@/assets/img/logo_fqm_branco.svg"),"alt":"Logo"},on:{"click":function($event){return _vm.goToRoute('Solicitacoes')}}}),_c('v-icon',{staticClass:"ma-2",attrs:{"color":"white"},on:{"click":function($event){$event.stopPropagation();_vm.drawer = !_vm.drawer}}},[_vm._v("mdi-menu")])],1),_c('v-navigation-drawer',{staticClass:"pa-2",attrs:{"absolute":"","right":"","temporary":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('nav',{staticClass:"nav"},[_c('ul',_vm._l((_vm.links),function(link,index){return _c('div',{key:index},[(
              _vm.checkPermission(link.permission) 
              && link.excludeTypePermission !== _vm.isRepresentante
              && _vm.customEnabled(link.customEnabled)
            )?_c('li',[(link.children.length)?_c('v-list-group',{attrs:{"value":true,"color":"fmq_black"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(link.title))])]},proxy:true}],null,true)},_vm._l((link.children),function(child,index){return _c('v-list',{key:index,staticClass:"pa-0"},[(_vm.checkPermissionChild(child)
                    && _vm.customEnabled(child.customEnabled))?_c('v-list-item',{staticClass:"pl-6",attrs:{"link":"","disabled":child.route === _vm.routeName}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.goToRoute(child.route)}}},[_vm._v(_vm._s(child.title))])],1):_vm._e()],1)}),1):_c('v-list-item',{on:{"click":function($event){return _vm.goToRoute(link.route)}}},[_vm._v(_vm._s(link.title))])],1):_vm._e()])}),0)]),_c('v-btn',{staticClass:"ma-2",attrs:{"color":"fmq_black","outlined":"","solo":"","width":"220px","loading":_vm.loading},on:{"click":function($event){_vm.$emit('logout');
        _vm.loading = true;}}},[_vm._v(" Sair "),_c('v-icon',{attrs:{"right":""}},[_vm._v(" mdi-exit-to-app ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }